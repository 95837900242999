/*****************************************
* Author: Maheshkarthik *
*****************************************/

/**** CSS Reset ****/

:root {
    /*TODO WHITE LABELING COLOR CODE*/
    --primary: #231F20;
    --secondary: #ECECE1;
    --active: #000;
    --error: red;
    --deal: #000;
    --white: #FFFFFF;

}

html,
body,
div,
span,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
img,
ins,
kbd,
q,
s,
samp,
strike,
strong,
dl,
dt,
dd,
ol,
ul,
li,
figure,
figcaption,
iframe,
fieldset,
form,
label,
legend {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    background: transparent;
}

a,
a:hover {
    text-decoration: none !important;
    transition: all .3s;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
}

html,
body {
    font-family: "Fort font", sans-serif;
    font-size: 14px;
}


.wrap {
    box-sizing: border-box;
    max-width: 1366px;
    padding: 0 80px;
    margin: 0px auto;
}

#header {
    background: var(--white);
    color: var(--primary);
    font-size: 1rem;
    max-height: 85px;
    padding: 30px 0;
}

.logo {
    max-width: 212px;
    max-height: 40px;
}

.mob-logo {
    max-width: 61px;
    max-height: 28px;
}


#header nav ul {
    list-style-type: none;
}

#header nav ul li {
    display: inline-block;
}

#header nav ul li a {
    color: var(--primary);
}

#header nav ul li a:hover,
#header nav ul li a.active {
    color: #777;
}

.cart {
    position: relative;
}

.user img,
.cart img {
    max-height: 21px;
    transition: all .3s;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
}

.cart>span {
    position: absolute;
    top: -10px;
    right: -10px;
    width: 22px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid var(--white);
    border-radius: 50%;
    background-color: var(--primary);
    color: var(--white);
    font-size: 0.625rem;
    font-weight: bold;
    text-align: center;
}

.user:hover,
.cart:hover {
    opacity: .7;
}

/********/

.section-heading {
    font-size: 1.571rem;
    font-weight: bold;
    color: var(--primary);
}

.btn-dark {
    color: var(--white);
    background-color: var(--deal);
    border-color: var(--deal);
}

.btn-dark:hover {
    color: var(--white);
    background-color: var(--deal);
}

.btn-outline-dark:hover {
    color: var(--white);
    background-color: var(--deal);
    border-color: var(--deal)
}

.btn-outline-dark {
    color: var(--primary);
    border-color: var(--deal);
}

.header-search {
    max-width: 250px;
    font-size: 1rem;
    padding-left: 22px;
    border-bottom: 1px solid var(--primary) !important;
    background-image: url("../img/search.svg");
    background-position: center left;
    background-repeat: no-repeat;
}

.home-menu .card.card-body {
    border-radius: 10px;
    max-height: 380px;
    overflow: hidden;
    transition: all .3s;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
}

.home-menu .card.card-body img {
    border-radius: 10px;
    width: 100%;
    height: auto;
}


.home-menu .card.card-body h3 {
    position: absolute;
    bottom: 25px;
    left: 20px;
    background-color: var(--white);
    padding: 14px 20px;
    color: var(--primary);
    font-size: 1rem;
    font-weight: bold;
    border-radius: 30px;
    transition: all .3s;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
}

.home-menu .card.card-body:hover {
    filter: brightness(90%);
}

.home-menu .card.card-body:hover h3 {
    bottom: 20px;
}

.view-all {
    text-align: right;
    margin: 70px 0;
    border-top: 1px solid #E0E0E0;
}

.view-all .btn {
    font-size: 1rem;
    font-weight: bold;
    margin-top: -33px;
    transition: all .3s;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
}

.view-all .btn img {
    margin-top: -3px;
}


.view-all .btn:hover {
    opacity: .7;
}

.home-banner {
    width: 100%;
    height: 440px;
    background-image: url("../img/banner_updated.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
}

/********/
.category-subnav {
    background: linear-gradient(180deg, var(--primary) 70%, var(--white) 30%);
}

.category-subnav h1 {
    font-size: 1.429rem;
    font-weight: bold;
    text-transform: uppercase;
}

.category-subnav ul {
    list-style-type: none;
}

.category-subnav ul li {
    display: inline-block;
    font-size: 1rem;
    font-weight: normal;
    color: var(--white);
    text-transform: capitalize;
}

.category-subnav ul li a {
    color: var(--white);
    text-transform: none;
    text-transform: inherit;
}


.category-subnav ul li a.current {
    font-weight: bold;
}

.category-subnav ul li a:hover {
    opacity: .8;
}

.category-subnav .dropdown .btn {
    vertical-align: baseline !important;
}

.category-subnav .dropdown li {
    display: block;
    padding: 5px;
}

.category-subnav .dropdown li a {
    padding: 5px 10px;
}

.cat-thumb {
    max-height: 168px;
    background-color: var(--white);
    box-shadow: 1px 1px 10px rgba(0, 0, 0, .15);
}

.product-thumb {
    text-align: center;
    display: block;
    background-color: #F8F8F8;
    padding: 30px 20px;
    margin-bottom: 5px;
    height: 288px;
}

.product-thumb img {
    margin: 0 auto;
    max-height: 100%;
    transition: all .3s;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
}

.product-thumb:hover img {
    transform: scale(1.1);
}


.products-grid .btn-outline-dark {
    height: 51px;
}

.big-btn {
    padding: 15px 80px;
    transition: all .3s;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
}

.big-btn1 {
    padding: 15px 78px;
    transition: all .3s;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
}

.big-btn:hover {
    opacity: .8;
}

.custom-field {
    border-bottom: 1px solid var(--primary) !important;
    padding: 14px 0;
}

#view-pass {
    transition: all .3s;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
}

#view-pass:hover {
    filter: brightness(30%);
}


/********/

.my-cart-list a:hover {
    opacity: .7;
}


.single-product {
    font-size: 1.222rem;
    font-weight: 400;
    border: 0;
    border-radius: 0;
    border-bottom: 1px solid #EBEBEB;
    padding: 0;
}

.single-product:last-child {
    border-bottom: none;
}

.my-cart-list .product-thumb {
    display: block;
    background-color: var(--white);
    /*max-width: 90px;*/
    width: 90px;
    max-height: 90px;
    text-align: center;
    overflow: hidden;
    padding: 0;
    margin: 8px 0;
}

.my-cart-list .product-thumb span {
    display: inline-block;
    height: 100%;
    width: auto;
    max-width: 90px;
    max-height: 90px;
    margin: 0 auto;
}

.my-cart-list .product-thumb:hover img {
    transform: none;
}

.my-cart-list .product-thumb img {
    max-height: 100%;
    max-width: 100%;
}

.my-cart-list .single-product .product-name {
    font-size: 1.222rem;
    color: var(--primary);
}


.my-cart-list .price,
.my-cart-list .single-product .price {
    text-align: right;
    font-size: 1.286rem;
    font-weight: bold;
    color: var(--primary);
}


.quantity,
.my-cart-list .quantity {
    border: 1px solid var(--primary);
    max-width: 100px;
    padding-left: 10px;
    padding-right: 10px;
}

.quantity .btn,
.quantity .form-control,
.my-cart-list .quantity .btn,
.my-cart-list .quantity .form-control {
    border: 0;
    background-color: transparent;
    font-size: 1.286rem;
    font-weight: bold;
}

.quantity.bigger {
    width: 250px;
}

.quantity.grid-btn {
    max-width: 100%;
    height: 51px;
    padding-left: 25px;
    padding-right: 25px;
}


.quantity.bigger .form-control {
    font-size: 2.444rem;
}


.order-summary hr {
    background-color: #999;
}

.order-summary-nutrition hr {
    background-color: rgb(19, 18, 18);
}

.next-btn img {
    filter: invert(1);
    margin-top: -2px;
}

.order-divider {
    border-top: 2px solid #999;
    border-bottom: 2px solid #999;
}

a.text-dark:hover {
    opacity: .7;
}

/********/

#addr-grid .card .card-body {
    min-height: 190px;
}

#manage-addr-grid .card .card-body {
    min-height: 145px;
}

#user-delivery {
    border: 1px solid #DCDCDC;
    border-radius: 100px;
    padding: 10px;
    max-width: 305px;
}

#user-delivery .nav-link {
    border: 0;
    border-radius: 100px;
    padding: 10px 15px;
    color: var(--primary);
}

#user-delivery .nav-link.active {
    background-color: var(--deal);
    color: var(--white);
    border: 0;
    border-radius: 100px;
}

.product-tag {
    background-color: #EFEBDB;
    color: #867535;
    font-weight: normal;
    letter-spacing: .8px;
}

a.product-tag:hover {
    color: #867535;
    opacity: .8;
}


.age-restrict {
    position: absolute;
    top: 20px;
    right: 5px;
    background-color: #f8d4d4;
    color: #AD1E1E;
    font-size: 11px;
    font-weight: normal;
    letter-spacing: .8px;
    padding: 4px 8px;
}

.age-restrict-text {
    display: inline-block;
    background-color: #f8d4d4;
    color: #AD1E1E;
    font-size: 11px;
    font-weight: normal;
    letter-spacing: .8px;
    padding: 4px 8px;
}

/********/

#footer {
    padding-bottom: 40px;
}

.footer-logo {
    margin-top: -22px;
}

#footer nav ul {
    list-style-type: none;
}

#footer nav ul li {
    display: inline-block;
}

#footer nav ul a {
    color: var(--primary);
    font-size: 1rem;
    font-weight: bold;
}

#footer nav ul a:hover {
    color: #666;
}


/*******/

#account-nav {
    background-image: url("../img/account/right-shadow.png");
    background-repeat: repeat-y;
    background-position: top right;
}

#account-nav ul {
    list-style-type: none;
}

#account-nav ul li {
    padding: 15px 0;
}

#account-nav ul li a {
    color: var(--primary);
    font-size: 1rem;
    font-weight: normal;
}

#account-nav ul li a:hover {
    opacity: .6;
}

#account-nav ul li a.active {
    font-weight: bold;
}

#account-nav ul li a img {
    width: 22px;
}


#faq .accordion-item {
    margin-bottom: 10px;
    border: 1px solid #707070;
}

#faq .accordion-button:focus {
    border-color: var(--primary);
    outline: 0;
    box-shadow: none;
}

#faq .accordion-button:not(.collapsed) {
    color: var(--primary);
    background-color: var(--white);
}

/***********/

.bill-details .list-of-items {
    max-height: 30vh;
    overflow: hidden;
    overflow-y: auto;
    padding-right: 10px;
    margin-right: -10px;
}

.dotted-divider {
    border-top: 1px dashed #999;
    border-bottom: 1px dashed #999;
}

.top-dotted-divider {
    border-top: 1px dashed #999;
}

.status-line {
    position: absolute;
    top: 38px;
    left: 0;
    right: 0;
    z-index: 1;
}

.status-row {
    z-index: 2;
}

.status-row .col img {
    border: 3px solid var(--white);
    border-radius: 50%;
}

hr.thick {
    background-color: #dbdbdb;
    height: 4px;
}


/********/

.offcanvas-menu {
    position: fixed;
    top: 0;
    bottom: 0;
    left: -350px;
    width: 300px;
    min-height: 100%;
    max-height: 100%;
    background-color: var(--white);
    z-index: 999;
    padding: 40px 35px;
    overflow-y: auto;
    transition: .4s all;
    -webkit-transition: .4s all;
    -moz-transition: .4s all;
    -o-transition: .4s all;
}

.offcanvas-menu.show {
    left: 0;
}

.cat-offcanvas {
    position: fixed;
    top: 0;
    bottom: 0;
    right: -280px;
    width: 250px;
    min-height: 100%;
    max-height: 100%;
    background-color: var(--white);
    z-index: 999;
    padding: 40px 30px;
    overflow-y: auto;
    transition: .4s all;
    -webkit-transition: .4s all;
    -moz-transition: .4s all;
    -o-transition: .4s all;
}

.cat-offcanvas.show {
    right: 0;
}


.cat-offcanvas nav a,
.offcanvas-menu nav a {
    display: block;
    padding: 12px 0;
    border-bottom: 1px solid #707070;
    font-size: 1em;
    font-weight: bold;
    line-height: 1.2em;
    color: var(--primary);
}

.cat-offcanvas nav span,
.offcanvas-menu nav span {
    display: block;
    padding: 12px 0;
    border-bottom: 1px solid #707070;
    font-size: 1em;
    color: var(--primary);
    line-height: 1.2em;
}

.offcanvas-menu nav span {
    display: block;
    padding: 12px 0;
    border-bottom: 1px solid #707070;
    font-size: 1em;
    color: var(--primary);
    line-height: 1.2em;
}

.cat-offcanvas nav a:last-child,
.offcanvas-menu nav a:last-child {
    border-bottom: 0;
}

.offcanvas-menu a.logout {
    color: #d50606;
}

.cat-offcanvas a:hover,
.offcanvas-menu a:hover {
    opacity: .7;
}


.close-cat-offcanvas,
.close-offcanvas {
    position: absolute;
    top: 10px;
    right: 10px;
    color: var(--primary) !important;
    font-size: 1rem;
    z-index: 990;
    padding: 10px;
}

.close-cat-offcanvas:hover,
.close-offcanvas:hover {
    color: #999 !important;
}



.cat-list nav a {
    display: block;
    padding: 12px 0;
    border-bottom: 1px solid #dcdcdc;
    font-size: 1em;
    line-height: 1.3em;
    color: var(--primary);
}

.cat-list nav span {
    display: block;
    padding: 12px 0;
    border-bottom: 1px solid #dcdcdc;
    font-size: 1em;
    line-height: 1.3em;
    color: var(--primary);
}

.submenu-link {
    background-image: url("../img/menu-plus.svg");
    background-repeat: no-repeat;
    background-position: center right;
}

.submenu {
    display: none;
}

.submenu li {
    display: block !important;
}

.submenu li a {
    padding: 10px 0;
}

.submenu-link.active {
    background-image: url("../img/menu-minus.svg");
}

.grid-icon {
    cursor: pointer;
    max-height: 22px;
}

.cat-heading {
    margin-top: 26px !important;
}

.modal-close {
    cursor: pointer;
    width: 38px;
    position: absolute;
    top: -15px;
    right: -15px;
    transition: all .3s;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
}

.modal-close:hover {
    filter: brightness(80%);
}


.single-order {
    color: var(--primary);
    transition: all .3s;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
}

.single-order:hover {
    color: #666;
}


.pagination .active .page-link {
    border-color: #333;
    font-weight: bold;
    background-color: var(--white);
}

.pagination .page-item .page-link {
    color: #333;
    box-shadow: none;
}


/***************/

#chat-window {
    background-color: #F5F5F5;
    height: 490px;
    width: 350px;
    position: fixed;
    right: 10%;
    bottom: 0;
    z-index: 999;
}

#chat-window .badge.bg-danger {
    display: inline-block;
    margin-top: -20px;
}

.chat-history {
    height: 350px;
    overflow: hidden;
    overflow-y: auto;
    margin-top: 20px;
    margin-bottom: 10px;
    margin-right: -21px;
    padding-right: 21px;
}

.chat-start {
    padding: 100px 50px 30px 50px;
}

#chat-window .input-group img {
    width: 22px;
    transition: all .3s;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
}

#chat-window .input-group img:hover {
    opacity: .6;
}


.chat-history ul {
    list-style-type: none;
    font-size: 1rem;
}

.chat-history ul .sender {
    float: right;
    background-color: var(--primary);
    padding: 6px 10px;
    margin-bottom: 7px;
    color: var(--white);
    width: auto;
    max-width: 80%;
    border-radius: 10px 10px 0 10px;
    clear: both;
}

.chat-history ul .receiver {
    float: left;
    background-color: #D6D3CC;
    padding: 6px 10px;
    margin-bottom: 7px;
    color: var(--primary);
    width: auto;
    max-width: 80%;
    border-radius: 10px 10px 10px 0;
    clear: both;
}

.chat-history ul .sender .time {
    display: block;
    font-size: 0.714rem;
    color: #b1b1b1;
    text-align: right;
}

.chat-history ul .receiver .time {
    display: block;
    font-size: 0.714rem;
    color: #606060;
    text-align: right;
}

#chat-window.compress {
    bottom: -430px;
}

#chat-collapse {
    cursor: pointer;
}

#chat-collapse.turn {
    transform: rotate(180deg);
}

/*
.age-check:before {
    content: "";
    background-image: url("../img/age-check.svg");
    background-repeat: no-repeat;
    width: 40px;
    height: 40px;
    text-align: center;
    position: absolute;
    top: 25px;
    right: 10px;
}
*/

#search-result-tabs .nav-item .nav-link {
    border: 0;
    color: #666;
    padding: 5px 40px;
    font-size: 1.143rem;
}

#search-result-tabs .nav-item.show .nav-link,
#search-result-tabs .nav-link.active {
    border: 0;
    border-bottom: 2px solid var(--primary);
    font-weight: bold;
    color: var(--primary);
}

.adjust-remove {
    margin-top: -10px;
}

.header-search-close {
    border-bottom: 1px solid var(--primary);
}

#refund-modal .col.px-0,
.make-it-wrap {
    word-wrap: anywhere;
}

#refund-modal .col-auto>span {
    display: flex;
    /* height: 100%; */
    width: auto;
    width: 70px;
    height: 70px;
    margin: 0 auto;
    justify-content: center;
}

#refund-modal .col-auto span img {
    max-height: 100%;
    max-width: 100%;
}

/**** Overwrite ****/

.btn:focus {
    box-shadow: none;
    outline: none;
}

.form-control,
.form-control:focus {
    border: 0;
    border-radius: 0;
    box-shadow: none;
    outline: none;
}

.btn {
    border-radius: 0;
    transition: all .3s;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
}


.modal a:hover {
    opacity: .7;
}

.breadcrumb-item a {
    color: var(--primary);
    font-size: .857rem;
    font-weight: normal;
}

.breadcrumb-item.active {
    color: var(--primary);
    font-weight: bold;
}

.white .breadcrumb-item a,
.white .breadcrumb-item.active {
    color: var(--white);
}


.category-subnav .breadcrumb-item a {
    font-size: 1rem;
}

.form-check-input:checked {
    background-color: var(--primary);
    border-color: var(--primary);
}

button.btn-dark:hover {
    opacity: .7;
}


.alert-danger {
    background-color: #FFEDED;
    color: var(--primary);
    border: 0;
}

.text-warning {
    color: #EF8F0C !important;
}





/*****************/
.bg-ltcream {
    background-color: var(--secondary);
}

.bg-cream {
    background-color: var(--secondary);
}

.bg-creamier {
    background-color: #f1ede1;
}

.bg-ltgrey {
    background-color: #F8F8F8;
}

.txtgrey {
    color: #878787;
}

.txtblue {
    color: #00A1DE;
}

.py-35 {
    padding-top: 35px;
    padding-bottom: 35px;
}

.py-50 {
    padding-top: 50px;
    padding-bottom: 50px;
}

.py-100 {
    padding-top: 100px;
    padding-bottom: 100px;
}

.my-75 {
    margin-top: 75px;
    margin-bottom: 75px;
}

.p-50 {
    padding: 50px;
}


.font42 {
    font-size: 3rem;
}

.font28 {
    font-size: 2rem;
}

.font20 {
    font-size: 1.429rem;
}

.font18 {
    font-size: 1.286rem;
}

.font16 {
    font-size: 1.143rem;
}

.font15 {
    font-size: 1.071rem;
}

.font14 {
    font-size: 1rem;
}

.font13 {
    font-size: .929rem;
}

.font12 {
    font-size: .857rem;
}

.sub-category-hide {
    width: 160px;
    height: 160px;
}

.card_height {
    height: 250px;
}

.card_images {
    height: 100% !important;
    width: auto;
}

.product_detail_image {
    width: 100%;
    height: 300px;
    object-fit: contain;
}

.btn_align {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 20px;
}

/**** Media Queries ****/

@media screen and (max-width: 1024px) {

    .wrap {
        padding-left: 20px;
        padding-right: 20px;
    }

    .wrap.py-50 {
        padding-top: 0;
        padding-bottom: 0;
    }

    .my-cart-list .single-product .product-name {
        font-size: 1rem;
    }

    .product-thumb {
        padding: 20px;
        height: 240px;
    }

    .product-thumb img {
        max-width: 100%;
        max-height: 100%;
    }

    .my-cart-list .product-thumb {
        max-width: 70px;
        max-height: 70px;
    }

    .quantity,
    .my-cart-list .quantity {
        max-width: 80px;
        padding-left: 5px;
        padding-right: 5px;
    }

    .quantity .btn,
    .quantity .form-control,
    .my-cart-list .quantity .btn,
    .my-cart-list .quantity .form-control {
        font-size: 1rem;
    }

    .my-cart-list .price,
    .my-cart-list .single-product .price {
        font-size: 1rem;
    }


    #header-shadow {
        border-bottom: 0 !important;
        box-shadow: none !important;
    }

    #header {
        padding: 20px 0;
        max-height: 130px;
    }

    .header-search {
        max-width: 100%;
        width: 100%;
        margin-top: 20px;
    }

    .home-banner-wrap {
        padding: 0;
        border-radius: 0 !important;
    }

    .home-banner {
        height: 140px;
        border-radius: 0 !important;
    }

    #footer nav ul li {
        margin-bottom: 25px;
    }

    .modal-body.p-50 {
        padding: 25px;
    }

    .cat-thumb {
        max-height: 120px;
    }

    #user-delivery {
        max-width: 247px;
    }

    .modal-close {
        top: -20px;
        right: -8px;
    }

    .bill-details .list-of-items {
        max-height: none;
    }

    .category-subnav {
        background: linear-gradient(180deg, var(--primary) 80%, var(--white) 20%);
    }

    .category-subnav h1 {
        font-size: 1.286rem;
    }

}


@media screen and (max-width: 990px) {
    .sub-category-hide {
        display: none;
    }

    .category-subnav {
        background: var(--primary);
    }

    .category-subnav .breadcrumb-item a,
    .category-subnav .breadcrumb-item.active {
        color: var(--white);
    }

    .product_detail_image {
        width: 100%;
        height: 200px;
        object-fit: contain;
    }

}

@media screen and (max-width: 576px) {

    .sub-category-hide {
        display: none;
    }

    .age-restrict,
    .age-restrict-text {
        letter-spacing: normal;
        padding: 4px;
    }

    .quantity,
    .my-cart-list .quantity {
        max-width: 66px;
        padding-left: 0;
        padding-right: 0;
    }

    .category-subnav .breadcrumb-item a,
    .category-subnav .breadcrumb-item.active {
        font-size: .857rem;
    }

    .section-heading {
        font-size: 1.143rem;
    }

    .font28 {
        font-size: 1.571rem;
    }

    .font18 {
        font-size: 1.143rem;
    }

    .font16 {
        font-size: 1rem;
    }

    .font14 {
        font-size: .857rem;
    }

    #chat-window {
        width: 90%;
        right: 0;
        left: 0;
        margin: 0 auto;
    }

}


@media screen and (max-width: 452px) {

    .my-cart-list .single-product .product-name {
        max-width: 140px;
    }

}



@media screen and (max-width: 390px) {

    .my-cart-list .single-product .product-name {
        max-width: 115px;
    }

}

.manage_payment .card-body {
    min-height: 100px !important;
}





.otp_pin .form-control {
    border: none !important;
    width: 100%;
}

.otp_pin .form-control input {
    width: 100% !important;
    border-bottom: 2px solid black;
    border-top: 0;
    border-left: 0;
    border-right: 0;
}