
 /*TODO WHITE LABELING FONT*/

@font-face {
  font-family: 'Fort font';
  font-style: normal;
  src: url(./font/Poppins-Regular.ttf);      /* TODO FONT CHANGES */
}

@font-face {
  font-family: 'Fort font Bold';
  font-style: bold;
  src: url(./font/Poppins-SemiBold.ttf);    /* TODO FONT CHANGES */
}

.modal-backdrop.show {
  opacity: .5;
}

.loader {
  position: fixed;
  z-index: 999999;
  background: rgba(255, 255, 255, 0.62);
  width: 100%;
  height: 100%;
}

.borderLine {
  /* height: 100px; */
  width: 50%;
  border: 1px solid black;
  border-collapse: collapse;
}

.loaderAlign {

  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  margin-left: -35px;
  margin-top: -35px;
  z-index: 9999;
}

p.errorMsg {
  color: red;
}

.text-link {
  cursor: pointer;
}

#footer nav ul span:hover {
  color: #666;
}

#footer nav ul span {
  color: #000;
  font-size: 1rem;
  font-weight: bold;
}

span.text-dark:hover {
  opacity: .7;
}

.pagination>.active>a,
.pagination>.active>a:focus,
.pagination>.active>a:hover,
.pagination>.active>span,
.pagination>.active>span:focus,
.pagination>.active>span:hover {
  z-index: 3;
  color: #fff;
  cursor: default;
  background-color: #333;
  border-color: #333;
}

.pagination>li>a:focus,
.pagination>li>a:hover,
.pagination>li>span:focus,
.pagination>li>span:hover {
  z-index: 2;
  color: #333;
  background-color: #eee;
  border-color: #ddd;
}

.pagination>li>a,
.pagination>li>span {
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px;
  line-height: 1.42857143;
  color: #333;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #ddd;
}

#account-nav ul li span.active {
  font-weight: bold;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.order-dividers {
  border-top: 1px dashed #999;
}

.p-relative {
  position: relative;
}

.password-icon {
  position: absolute;
  right: 0;
  top: 20px;
}

.big-btn-myaccount {
  padding: 15px 0px;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
}


.product-thumb {
  align-items: center;
  display: flex !important;
}

.eighteen {
  position: absolute;
  width: 25px;
  top: 0px;
  right: -10px;
}

.payment-green {
  color: rgb(3, 194, 3);
}

.payment-red {
  color: red;
}

.payment-metalic {
  color: #077e8c;
}

.payment-yellow {
  color: yellow;
}

.payment-blue {
  color: blue;
}

.payment-gray {
  color: gray;
}

.offcanvas-menu nav span {
  display: block;
  padding: 12px 0;
  border-bottom: 1px solid #707070;
  font-size: 1em;
  color: #000;
}

.offcanvas-menu nav span:last-child {
  border-bottom: 0;
}

.offcanvas-menu span.logout {
  color: #d50606;
}

.offcanvas-menu span:hover {
  opacity: .7;
}

.submenu li span {
  padding: 10px 0;
}

.offcanvas-menu {
  z-index: 1000;
}

.quantity.grid-btn.qty-adj-detail {
  margin-bottom: 3rem;
  max-width: 265px;
}

.product-list {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.age-restriction {
  position: absolute;
  top: 13px;
  right: 2px;
  background: #000000;
  color: #FFFFFF;
  padding: 5px;
  border-radius: 5px 0.25rem 5px 5px;
}

.age-restriction img {
  width: 20px;
}

/* .p-detail {
  background-color: #F8F8F8
} */

.p-detail .age-restriction {
  top: 0px;
}

#header nav ul li span:hover,
#header nav ul li span.active {
  color: #000;
}

/* #header nav ul li span {
  font-weight: bold;
} */

.forgot-input:disabled {
  background-color: white;
  opacity: 1;
}

.menu-active {
  font-weight: bold;
}

.text-truncate.text-truncate--2 {
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.cursor-pointer {
  cursor: pointer;
}

.accordion-button:after {
  transform: rotate(270deg);
}

.form-select:focus {
  box-shadow: none;
}

.product-menu-item-ul {
  display: flex;
  flex-direction: row;
}

/* .product-menu-item-ul li {
  flex: 1;
} */
.product-menu-item-ul li span {
  height: 20px;
  display: -webkit-box !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: normal;
  overflow: hidden;
}

.page-content-container {
  position: relative;
  min-height: 100vh;
  padding-bottom: 200px;
}

#footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}

/* 350 */


@media (max-width: 575.98px) {
  .page-content-container {
    padding-bottom: 285px;
  }

  .footer-center-content {
    text-align: center;
  }
}

.float_right {
  float: right;
}

.text-red {
  color: red;
}



.mh-65 {
  min-height: 65px;
  justify-content: center;
}

.google-places-autocomplete__input {

  border-bottom: 1px solid #000 !important;
  display: block;
  width: 100%;
  padding: 14px 0 0.375rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color .15s
}

.google-places-autocomplete__input,
.google-places-autocomplete__input:focus {
  border: 0;
  border-radius: 0;
  box-shadow: none;
  outline: none;
}

.title-case {
  text-transform: capitalize !important;
}

.bg-ash {
  background-color: #efefef;
}

.modal-product-close {
  cursor: pointer;
  position: relative;
  z-index: 10;
  top: 0px;
  right: 0px;
}

.modal-product-close img {
  position: absolute;
  width: 20px !important;
  top: 0px;
  right: 0px;
}




.img-placeholder {
  background-color: black;
  justify-content: center;
  display: flex;
  color: white;
  font-size: 30px;
  cursor: pointer;
  align-items: center;
  border-radius: 50px;
}

.drag-card {
  height: 300px;
  background-color: #f4f4f4;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  border-radius: 10px;
}

input::-ms-reveal {
  display: none;
}

.pin-otp {
  width: 50%;
}

.strike {
  text-decoration: line-through;
  text-decoration-color: red;
}

.lineNutri {
  margin-top: 8px;
  margin-bottom: 8px;
  background-color: rgb(19, 13, 13);
  width: 100%;
  height: 3px;
}

.biglineNutri {
  margin-top: 12px;
  margin-bottom: 12px;
  background-color: rgb(19, 13, 13);
  width: 100%;
  height: 5px;
}

.smalllineNutri {
  margin-top: 5px;
  margin-bottom: 5px;
  background-color: rgb(19, 13, 13);
  width: 100%;
  height: 1px;
}

.small {
  font-size: x-small;
}

.refund-bg {
  background-color: lightgrey;
  width: 100%;
  height: 100%;
}


.drop-zone {
  padding: 25px;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: "Quicksand", sans-serif;
  font-weight: 500;
  font-size: 20px;
  cursor: pointer;
  color: #cccccc;
  border: 4px dashed #000000;
  border-radius: 10px;
}

.drop-zone--over {
  border-style: solid;
}

.drop-zone__input {
  display: none;
}

.drop-zone__thumb {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
  background-color: #cccccc;
  background-size: cover;
  position: relative;
}

.drop-zone__thumb::after {
  content: attr(data-label);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 5px 0;
  color: #ffffff;
  background: rgba(0, 0, 0, 0.75);
  font-size: 14px;
  text-align: center;
}

.refundButton {
  border-radius: 50%;
  width: 50%;
}

.upload-avatar {
  background-color: #000000;
  color: white;
}

@media (min-width: 576px) {
  .modal-dialog-centered {
    min-height: calc(100% - 1rem);
  }
}

.p-40 {
  padding: 40px;
}

.google-places-autocomplete__suggestion {
  cursor: pointer;
}

.other-border {
  border-bottom: 1px solid black;
}

.desc-wrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
  flex-wrap: wrap;
}
.map-color {
  color: #0d6efd;
  cursor: pointer;
}

.iframe-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.custom-border {
  border-right: 1px solid grey;
}

.copyText {
  color: #0d6efd;
  text-decoration: underline;
  cursor: pointer;
}